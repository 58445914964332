body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    overflow-x: hidden;
    box-sizing: border-box;
    color: #253725;
    max-width: 2048px;
    margin: 0 auto;
}

.modile-container {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.mobile-container-item {
    display: none !important;
}

.mobile-container-item a {
    text-decoration: none;
}

/* Работа переключателя */
#toggle {
    position: absolute;
    left: -100%;
    top: -100%;
}

#toggle:checked ~ .toggle-container .button-toggle:before {
    transform: translateY(-50%) rotate(45deg) scale(1);
}

#toggle:checked ~ .toggle-container .button-toggle:after {
    transform: translateY(-50%) rotate(-45deg) scale(1);
}

#toggle:checked ~ .nav {
    margin-bottom: 100px;
    pointer-events: auto;
    transform: translate(0, 50px);
}

#toggle:checked ~ .nav .nav-item {
    width: 100%;
    border-bottom: 1px #b7f0b0 solid;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    height: 40px;
    line-height: 40px;
    margin-top: 0;
    opacity: 1;
    transform: scaleY(1);
    transition: all 0.3s linear;
    padding-bottom: 3px;
}

#toggle:checked ~ .nav .nav-item:nth-child(1) {
    transition-delay: 0.15s;
}

#toggle:checked ~ .nav .nav-item:nth-child(1):before {
    transition-delay: 0.15s;
}

#toggle:checked ~ .nav .nav-item:nth-child(2) {
    transition-delay: 0.1s;
}

#toggle:checked ~ .nav .nav-item:nth-child(2):before {
    transition-delay: 0.1s;
}

#toggle:checked ~ .nav .nav-item:nth-child(3) {
    transition-delay: 0.05s;
}

#toggle:checked ~ .nav .nav-item:nth-child(3):before {
    transition-delay: 0.05s;
}

#toggle:checked ~ .nav .nav-item:nth-child(4) {
    transition-delay: 0s;
}

#toggle:checked ~ .nav .nav-item:nth-child(4):before {
    transition-delay: 0s;
}

#toggle:checked ~ .nav .nav-item:before {
    opacity: 0;
}

.nav-item .button-container {
    justify-content: flex-start;
}

.nav-item i {
    padding-left: 10px;
}

.button-toggle {
    left: -16px;
    top: -3px;
    position: absolute;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: 25px;
    background-color: transparent;
    border: 1px #000000 solid;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.6s;
}

.button-toggle:hover {
    box-shadow: inset 0 0 0 20px rgba(45, 220, 19, 0.1);
}

.button-toggle:before,
.button-toggle:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000000;
    border-radius: 5px;
    transition: 0.5s;
}

.button-toggle:before {
    transform: translateY(-50%) rotate(45deg) scale(0);
}

.button-toggle:after {
    transform: translateY(-50%) rotate(-45deg) scale(0);
}

/* Меню */
.nav {
    position: relative;
    right: 10px;
    display: inline-block;
    padding: 31px 29px 20px;
    pointer-events: none;
    transition: 0.5s;
}

.nav-item {
    color: #000000 !important;
    position: relative;
    display: inline-block;
    float: left;
    clear: both;
    font-size: 0;
    height: 8px;
    width: 22px;
    line-height: 10px;
    text-transform: uppercase;
    white-space: nowrap;
    transform: scaleY(0.2);
    transition: 0.5s, opacity 1s;
}

.nav-item:nth-child(1) {
    transition-delay: 0s;
}

.nav-item:nth-child(1):before {
    transition-delay: 0s;
}

.nav-item:nth-child(2) {
    transition-delay: 0.05s;
}

.nav-item:nth-child(2):before {
    transition-delay: 0.05s;
}

.nav-item:nth-child(3) {
    transition-delay: 0.1s;
}

.nav-item:nth-child(3):before {
    transition-delay: 0.1s;
}

.nav-item:nth-child(4) {
    transition-delay: 0.15s;
}

.nav-item:nth-child(4):before {
    transition-delay: 0.15s;
}

.nav-item:nth-child(n + 5) {
    margin-top: -7px;
    opacity: 0;
}

.nav-item:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #000000;
    transform: translateY(-50%) scaleY(5);
    transition: 0.5s;
}

/* DROPDOWN MENU*/
.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: flex;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    top: 50px;
    left: 0px;
    background-color: #FCFBF2;
    min-width: 360px;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-radius: 4px;
    border: 1px solid #2ddc13;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 16px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #2ddc13;
    color: #ffffff;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .anchor-link {
    color: #2ddc13;
}

/* DROPDOWN MENU*/

/* MARGINS */
.xxxl-margin-bottom {
    margin-bottom: 112px;
}

@media screen and (max-width: 1480px) {
    .xxxl-margin-bottom {
        margin-bottom: 72px;
    }
}

.xxl-margin-bottom {
    margin-top: 0;
    margin-bottom: 64px;
}

.xl-margin-bottom {
    margin-top: 0;
    margin-bottom: 56px;
}

.lg-margin-bottom {
    margin-top: 0;
    margin-bottom: 48px;
}

.md-margin-bottom {
    margin-top: 0;
    margin-bottom: 32px;
}

.sm-margin-bottom {
    margin-top: 0;
    margin-bottom: 24px;
}

@media screen and (max-width: 768px) {
    .xxl-margin-bottom {
        margin-bottom: 64px;
    }

    .xl-margin-bottom {
        margin-bottom: 56px;
    }

    .lg-margin-bottom {
        margin-bottom: 48px;
    }

    .md-margin-bottom {
        margin-bottom: 32px;
    }

    .sm-margin-bottom {
        margin-bottom: 24px;
    }
}

/* BUTTONS */

.button-container {
    display: flex;
    z-index: 99;
}

.button-container .button_default-filled {
    margin-right: 20px;
    align-items: center;
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fbf9ee;
    border-radius: 100%;
    height: 84px;
    width: 84px;
    margin-right: 32px;
    transition: all 0.3s linear;
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.icon-container:hover {
    transform: scale(1.2);
    background-color: #2ddc13;
    color: white;
}

section {
    max-width: 1408px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.row-block {
    max-width: 1408px;
    width: 100%;
    margin: 0 auto;
}

@media screen and (max-width: 1428px) {

}

@media screen and (max-width: 768px) {
    .icon-container {
        height: 64px;
        width: 64px;
        margin-right: 24px;
        margin-top: 10px;
    }

    .section {
        margin-right: 44px;
        margin-left: 44px;
    }
}

@media screen and (max-width: 1480px) {
    .section {
        margin: 0 75px 40px;
    }
}

.header-row-block {
    padding: 64px;
}

@media screen and (max-width: 768px) {
    .row-block {
        padding: 0;
        margin: 0;
    }

    .column-block {
        padding: 48px;
    }
}

.column-block {
    flex: 1;
    padding: 64px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #ffffff;
    margin: 0 20px;
}

.column-block:first-of-type {
    margin-left: 0;
}

.column-block:last-of-type {
    margin-right: 0;
}

/* HEADER PAGE */
.full-width-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.header-container {
    position: relative;
    max-width: 1408px;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    margin-top: 32px;
}


@media screen and (max-width: 768px) {
    .triangle-background {
        border-bottom: 20vh solid #fbf9ee;
    }
}

.header-bar {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;
    padding: 32px 0;
}

.header-logo {
    position: relative;
    top: -6px;
    margin-right: 92px;
}

.header-logo.burger {
    position: absolute;
    left: 90px;
    top: 20px;
}

@media screen and (max-width: 1480px) {
    .header-anchors {
        flex: 3 !important;
    }

    .header-container {
        margin: 0 75px;
    }
}

@media screen and (max-width: 768px) {
    .mobile-container-item {
        display: block !important;
    }

    .modile-container {
        padding-bottom: 20px;
    }

    .header-bar {
        /*flex-direction: column;*/
        /*margin-bottom: 80px;*/
        display: none;
    }

    .header-container {
        /*min-height: 85vh;*/
        margin-left: 44px;
        margin-right: 44px;
    }
}

.header-buttons {
    display: flex;
    flex-direction: row;
    flex: 3;
}

.header-anchors {
    flex: 4;
    display: flex;
    justify-content: flex-start;
}

.anchor-link {
    white-space: nowrap;
    margin-right: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    cursor: pointer;
    transition: all 0.3s linear;
    text-decoration: none;
    color: black;
}

.anchor-link:hover {
    color: #2ddc13;
}

.header-actions {
    flex: 2;
    display: flex;
}

.header-icon {
    margin-right: 40px;
    cursor: pointer;
    transition: all 0.3s linear;
    height: 50px;
    width: 50px;
    color: black;
    font-size: 1.7em;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-icon:hover {
    transform: scale(1.3);
    color: #2ddc13;
}

@media screen and (max-width: 768px) {
    .header-icon {
        margin: 0 15px;
    }

    .anchor-link {
        margin: 0 15px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
    }

    .header-buttons {
        width: 100%;
        justify-content: stretch;
    }

    .header-anchors {
        flex: 2 !important;
    }

    .header-logo {
        margin-bottom: 40px;
    }
}

.content-container,
.help-content-container {
    max-width: 1408px;
    display: flex;
    justify-content: flex-start;
    z-index: 10;
}

.help-content-container {
    width: 100%;
}

.widget {
    width: 100%;
}

@media screen and (max-width: 1480px) {
    .content-container {
        margin: 0 75px;
    }

    .help-center-image {
        top: -130px;
        right: -140px !important;
    }

    .help-content-container {
        margin: 0 75px;
    }
}

@media screen and (max-width: 768px) {
    .content-container {
        max-width: 768px;
        display: flex;
        justify-content: flex-start;
        z-index: 10;
        padding-left: 44px;
    }

    .help-content-container {
        flex-direction: column;
        padding: 44px;
        align-items: baseline;
    }

    .column-block {
        margin: 0;
    }

    .widget {
        padding-top: 32px;
        width: 100%;
    }
}

.no-padding {
    padding: 0;
}

.no-margin-top {
    margin-top: 0;
}

.xxl-padding {
    padding-bottom: 112px;
    padding-top: 112px;
}

.icons-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 25px;
}

.help-center-image {
    position: absolute;
    right: 0;
}

.logo-star {
    position: absolute;
    left: 112px;
    top: 0;
    mix-blend-mode: overlay;
    opacity: 0.6;
}

.help-honest-people {
    background: rgb(202,234,255);
    background: linear-gradient(153deg, rgba(202,234,255,1) 0%, rgba(213,254,162,1) 100%);
}

@media screen and (max-width: 768px) {
    .help-center-image {
        opacity: 0.2;
        left: 280px;
        height: 880px;
    }

    .logo-star {
        left: -70px;
        top: -40px;
    }
}

/* sum-buttons-container */

.sum-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 50px;
}

.sum-buttons {
    display: flex;
}

.sum-buttons input {
    display: none;
}

.sum-buttons label {
    position: relative;
    color: #202f24;
    background-color: #ffffff;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    width: 96px;
    padding: 16px 0;
    margin-right: 16px;
    display: block;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: all 0.3s linear;
}

.sum-buttons label:last-of-type {
    margin-right: 24px;
}

.sum-buttons input:checked + label {
    border: 1px solid #2ddc13;
    color: #2ddc13;
}

.sum-buttons input:checked:hover + label {
    border: 1px solid #2ddc13;
    color: #000000;
    background: #b7f0b0;
}

.sum-buttons input:hover + label {
    border: 1px solid #2ddc13;
    color: #ffffff;
    background: #2ddc13;
}

/* CUSTOM INPUT*/
input[type="text"] {
    color: #202f24;
    background-color: #ffffff;
    font-size: 20px;
    line-height: 24px;
    width: 100%;
    padding: 16px 24px;
    display: block;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid transparent;
    outline: none;
    transition: all 0.3s linear;
}

input[type="text"]:focus {
    border: 1px solid #2ddc13 !important;
    color: #2ddc13;
}

input[type="text"]:hover {
    border: 1px solid #2ddc13 !important;
}

/* CUSTOM SELECT */

.select-box {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto;
    font-size: 18px;
    color: #60666d;
}

.select-box__current {
    position: relative;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    outline: none;
}

.select-box__current:focus + .select-box__list {
    opacity: 1;
    -webkit-animation-name: none;
    animation-name: none;
}

.select-box__current:focus + .select-box__list .select-box__option {
    cursor: pointer;
}

.select-box__current:focus .select-box__icon {
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
}

.select-box__current:focus {
    color: #2ddc13;
}

.select-box__icon {
    position: absolute;
    top: 50%;
    right: 30px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 20px;
    opacity: 0.3;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
}

.select-box__value {
    display: flex;
}

.select-box__input {
    display: none;
}

.select-box__input:checked + .select-box__input-text {
    display: block;
}

.select-box__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 16px 24px;
    border-radius: 4px;
    background-color: #fff;
    transition: all 0.3s linear;
}

.select-box__input-text:hover {
    color: #2ddc13;
}

.select-box__list {
    margin: 4px 0;
    position: absolute;
    width: 100%;
    z-index: 9999;
    padding: 0;
    list-style: none;
    opacity: 0;
    border-radius: 4px;
    -webkit-animation-name: HideList;
    animation-name: HideList;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: step-start;
    animation-timing-function: step-start;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}

.select-box__option {
    display: block;
    padding: 16px 24px;
    background-color: #fff;
}

.select-box__option:hover,
.select-box__option:focus {
    color: #ffffff;
    background: #2ddc13;
}

@-webkit-keyframes HideList {
    from {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
    to {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
    }
}

@keyframes HideList {
    from {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
    to {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
    }
}

/* FOOTER */
.footer-container {
    flex: 1;
    display: flex;
    max-width: 1408px;
    padding: 10px 0;
    justify-content: space-between;
    color: #ffffff;

    align-items: center;
}

@media screen and (max-width: 1480px) {
    .footer-container {
        margin: 0 75px;
    }
}

@media screen and (max-width: 768px) {
    .footer-container {
        max-width: 768px;
    }
}

.footer-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 3;
}

.footer-logo-container .footer-logo {
    display: inline-block;
    margin-right: 32px;
}

.footer-logo-container .footer-logo img {
    max-width: 100%;
}

.footer-year-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}

.up-button {
    height: 64px;
    width: 64px;
    border-radius: 100%;
    background: #ffffff;
    border: 2px solid #2ddc13;
    cursor: pointer;
    margin-left: 152px;
}

@media screen and (max-width: 1480px) {
    .up-button {
        margin-left: 75px;
    }
}

@media screen and (max-width: 768px) {
    .up-button {
        margin-left: 48px;
    }
}

.up-button-icon {
    position: relative;
    top: 20px;
    left: 16px;
}

.girl-background {
    background: url("assets/images/header-girl.png");
    background-repeat: no-repeat;
}

@media screen and (max-width: 420px) {
    .header-bar {
        display: none;
    }

    .mobile-container-item {
        display: block !important;
    }

    .full-width-wrapper {
        flex-direction: column;
    }

    .triangle-background {
        display: block;
        position: inherit;
    }

    .header-bar {
        margin-bottom: 30px;
    }

    .header-container {
        margin: 0;
    }

    .girl-background {
        position: inherit;
    }

    .girl-background img {
        width: 100%;
    }

    .row-block {
        padding: 32px 24px;
        margin: 0;
    }

    .section {
        margin: 0 24px 32px;
    }

    .column-block {
        padding: 32px 24px;
    }

    .content-container {
        padding: 0;
        margin: 0;
    }

    .button-container {
        justify-content: center;
        width: 100%;
        text-decoration: none;
    }

    .button_default-filled,
    .button_default {
        width: 100%;
        justify-content: center;
    }

    .sum-buttons-container {
        flex-wrap: wrap;
    }

    .sum-buttons label:last-of-type {
        margin-right: 0;
    }

    .sum-buttons-container input {
        margin-top: 16px;
    }

    .sum-buttons {
        width: 100%;
        justify-content: space-between;
    }

    .sum-buttons label {
        margin-right: 8px;
    }

    #select-sums-container {
        width: inherit !important;
    }

    .help-content-container {
        padding: 0;
        margin: 0;
    }

    .widget {
        margin: 0 auto;
        width: auto;
    }

    .icons-wrapper {
        flex-wrap: wrap;
    }

    .help-center-image {
        opacity: 0.2;
        left: inherit;
        right: 0 !important;
        top: inherit;
        height: 550px;
        bottom: 0;
    }

    .footer-container {
        margin: 0 16px;
    }

    .footer-logo-container h2 {
        font-size: 12px;
    }

    .footer-logo-container .footer-logo {
        margin-right: 12px !important;
    }

    .footer-year-container span {
        font-size: 12px;
    }

    .up-button {
        height: 32px;
        width: 32px;
        margin-left: 32px;
    }

    .up-button-icon {
        height: 12px;
        left: 7px;
        top: 5px;
    }
}

.active, .accordion:hover {
    color: #ffffff;
}

.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    display: flex;
    flex-direction: column;
}

.panel a {
    text-transform: uppercase;
    white-space: nowrap;
    border-bottom: 1px #b7f0b0 solid;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    height: 40px;
    line-height: 40px;
    margin-top: 0;
    opacity: 1;
    transform: scaleY(1);
    transition: all 0.3s linear;
    padding-bottom: 3px;
}

.active .mobile-arrow-down {
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
}

.mobile-arrow-down {
    position: absolute;
    top: 50%;
    right: 30px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 20px;
    opacity: 0.3;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
}

.map-wrapper {
    height: 960px;
    position: relative;
}

.map-container {
    position: absolute;
    width: 448px;
    top: 64px;
    left: 50%;
    margin: 0 0 0 -704px;
    z-index: 100;
}

.map-banner {
    padding: 40px;
    background: #fff;
    box-shadow: 0px 0px 40px 8px rgba(101, 101, 101, 0.08);
}

.map-banner-title {
    padding-right: 108px;
    background: url("./assets/icons/leadership.png") right center no-repeat transparent;
    font-size: 32px;
    line-height: 120%;
    margin: 0 0 48px;
}

.map-banner p {
    font-size: 16px;
}

.help-list-wrapper {
    padding: 0 360px 0 0;
    margin-bottom: 112px;
}

.help-list {
    margin: 0 0 56px;
}

.help-list li {
    list-style-type: none;
    display: flex;
    align-items: center;
}

.help-list li:last-child {
    margin-bottom: 0;
}

.help-list-icon {
    margin-right: 32px;
    height: 88px;
}

.alert-block {
    border: 2px solid #BB5B3F;
    padding: 48px 328px 48px 56px;
    background: url('assets/icons/book.png') right 104px center no-repeat;
}

.alert-block-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 130%;
    color: #BB5B3F;
    margin-bottom: 24px;
}

.alert-block p {
    font-size: 16px;
}

.alert-block p:last-child {
    margin-bottom: 0;
}

.actions-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.actions-clouds-number {
    font-weight: bold;
    font-size: 56px;
    line-height: 1;
    color: #2DDC13;
    display: block;
    margin: 0 0 15px;
    padding: 0;
}

.actions-clouds {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.actions-clouds-steps {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.actions-clouds-finish {
    width: 448px;
    margin-left: 72px;
}

.actions-clouds-cloud-wrap {
    flex: 50%;
    margin-bottom: 20px;
}

.actions-clouds-cloud-wrap.first {
    order: 1;
    position: relative;
}

.actions-clouds-cloud-wrap.first:after {
    content: '';
    display: block;
    background: url('assets/icons/arrow-1.png') no-repeat;
    width: 54px;
    height: 70px;
    position: absolute;
    bottom: -80px;
    left: 40px;
}

.actions-clouds-cloud-wrap.second {
    order: 3;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.actions-clouds-cloud-wrap.second:after {
    content: '';
    display: block;
    background: url('assets/icons/arrow-2.png') no-repeat;
    width: 54px;
    height: 70px;
    position: absolute;
    top: -80px;
    right: 10px;
}

.actions-clouds-cloud-wrap.third {
    order: 2;
    position: relative;
}

.actions-clouds-cloud-wrap.third:after {
    content: '';
    display: block;
    background: url('assets/icons/arrow-3.png') no-repeat;
    width: 25px;
    height: 50px;
    position: absolute;
    left: 70px;
    bottom: -60px;
}

.actions-clouds-cloud-wrap.fourth {
    display: flex;
    justify-content: flex-end;
    order: 4;
    position: relative;
}

.actions-clouds-cloud-wrap.fourth:after {
    content: '';
    display: block;
    background: url('assets/icons/arrow-4.png') no-repeat;
    width: 64px;
    height: 80px;
    position: absolute;
    top: -80px;
    right: -56px;
    transform: rotate(15deg);
}

.actions-clouds-cloud {
    width: 336px;
    background: #fff;
    box-shadow: 0px 0px 40px 8px rgba(101, 101, 101, 0.08);
    padding: 25px;
    box-sizing: border-box;
}

.actions-clouds-cloud p {
    font-size: 16px;
}

.actions-clouds-cloud p a {
    font-weight: bold;
    color: #2DDC13;
}

.actions-clouds-cloud .button-container {
    justify-content: center;
    margin-top: 32px;
}

.actions-clouds-cloud .button-container .button_default {
    justify-content: center;
    width: 90%;
}

.actions-clouds-cloud .button-container .button_default img {
    margin-right: 15px;
}

.actions-clouds-cloud-wrap.fifth .actions-clouds-cloud {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.worker-man-background {
    width: 100%;
    background: url('assets/images/header-man.png') no-repeat right top;
    background-size: 640px auto;
    overflow: hidden;
    min-height: 100vh;
}

.worker-man-background .full-width-wrapper {
    min-height: 50vh;
}

.worker-women-background {
    width: 100%;
    background: url('assets/images/worker-girl.png') no-repeat right top;
}

.header-slogan {
    padding-bottom: 25px;
}

.header-slogan-image {
    margin-bottom: 15px;
    width: 420px;
}

.bot-info-wrap {
    position: relative;
}

.bot-info-wrap:after {
    content: '';
    display: block;
    position: absolute;
    z-index: -2;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #fbf9ee;
}

.bot-info {
    width: 100%;
    max-width: 1408px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-top: 45px;
    padding-bottom: 112px;
    position: relative;
}

.bot-info-counter {
    position: relative;
}

.bot-info-counter::after {
    content: '';
    display: block;
    background: url('assets/images/white-star-large-logo.png') no-repeat;
    width: 200px;
    height: 190px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.bot-info-counter-count {
    font-weight: bold;
    font-size: 100px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.2px;
    color: #BB5B3F;
    margin: 0;
    padding: 0;
}

.bot-info-counter-subtitle {
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.3px;
    color: #1F2421;
}

.bot-info-description {
    margin-left: 150px;
}

.triangle-background {
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 50vh solid #fbf9ee;
    border-left: 100vw solid transparent;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    z-index: -1;
}

.map-balloon-content-header p {
    font-weight: bold;
    color: #253725;
}

.map-balloon-content-body p {
    font-size: 14px;
    color: #253725;
}

.bysol-logo {
    align-self: center;
    background: url("assets/logos/bysol.png") no-repeat;
    display: inline-block;
    background-size: 66%;
    width: 120px;
    height: 22px;
}

.mobile-bysol-logo {
    background: url("assets/logos/bysol.png") no-repeat;
    background-size: 60px 14px;
    background-position: left;
}

@media screen and (max-width: 1480px) {
    .worker-man-background {
        background-size: 610px auto;
    }

    .header-slogan {
        width: 60%;
    }

    .bot-info-counter {
        flex: 50%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .bot-info-description {
        flex: 50%;
        margin: 0;
        padding-left: 20px;
        padding-right: 20px;
    }

    .map-container {
        left: 75px;
        margin: 0;
        top: 48px;
    }

    .map-wrapper {
        height: 683px;
    }

    .xxl-padding {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .worker-women-background {
        box-sizing: border-box;
        background-size: 450px auto;
        padding-left: 75px;
        padding-right: 75px;
    }

    .help-list-wrapper {
        margin-bottom: 75px;
    }

    .alert-block {
        border: 2px solid #BB5B3F;
        padding: 25px 328px 25px 25px;
    }

    .actions-clouds-cloud {
        width: 340px;
    }

    .actions-clouds-finish {
        width: 400px;
        margin-left: 30px;
    }

    .actions-clouds-cloud-wrap {
        margin-bottom: 50px;
    }

    .actions-clouds-cloud-wrap:after {
        background-size: 100% 100%;
    }

    .actions-clouds-cloud-wrap.first:after {
        background: url("assets/icons/arrow-3.png") no-repeat;
        width: 25px;
        height: 50px;
        bottom: -65px;
    }

    .actions-clouds-cloud-wrap.third:after {
        left: 50px;
        bottom: -70px;
    }

    .actions-clouds-cloud-wrap.second:after {
        width: 40px;
        height: 55px;
        background-size: 100% 100%;
        position: absolute;
        top: -67px;
        right: 9px;
    }

    .actions-clouds-cloud-wrap.fourth:after {
        background: url('assets/icons/arrow-2.png') no-repeat;
        width: 40px;
        height: 55px;
        background-size: 100% 100%;
        position: absolute;
        transform: none;
        top: -70px;
        right: -20px;
    }

    .help-content-container .column-block {
        width: 50%;
    }

    .icon-container {
        height: 54px;
        width: 54px;
        font-size: 14px;
    }
}

@media screen and (max-width: 1348px) {
    .xxl-padding {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .map-container {
        left: 45px;
        right: 45px;
        top: -100px;
        width: auto;
    }

    .help-content-container {
        display: block;
        margin: 0;
        padding: 0 45px;
    }

    .help-content-container .column-block {
        width: 100%;
    }

    .actions-clouds {
        display: block;
    }

    .actions-clouds-steps {
        align-items: stretch;
        justify-content: space-between;
    }

    .actions-clouds-cloud-wrap {
        width: 48%;
        margin: 0 0 25px;
        flex: initial;
    }

    .actions-clouds-cloud-wrap.second {
        order: 2;
    }

    .actions-clouds-cloud-wrap.third {
        order: 3;
    }

    .actions-clouds-cloud-wrap:after {
        display: none !important;
    }

    .actions-clouds-cloud {
        width: auto;
        height: 100%;
    }

    .actions-clouds-finish {
        width: auto;
        margin: 0;
    }

    .actions-clouds-cloud-wrap.fifth {
        width: 100%;
        margin: 0 0 25px;
        flex: initial;
    }

    .actions-clouds-cloud-wrap.fifth .actions-clouds-cloud {
        width: auto;
        padding: 25px;
    }

    .bot-info {
        padding-bottom: 125px;
    }

    .map-banner-title {
        padding-right: 0;
        margin: 0 0 25px;
        background: none;
    }

    .map-banner {
        padding: 25px;
        background: url("assets/icons/leadership.png") right 25px top 15px no-repeat #fff;
    }

    .bot-info-counter-count {
        font-size: 70px;
    }

    .bot-info-counter::after {
        width: 140px;
        height: 140px;
        background-size: 100%;
    }

    .bot-info-counter-subtitle {
        font-size: 16px;
    }

    .worker-women-background {
        padding-left: 45px;
        padding-right: 45px;
        background: url("assets/backgrounds/girl-768.png") right top no-repeat;
    }

    .help-list-wrapper {
        margin: 0 0 45px;
        padding: 0;
    }

    .help-list {
        margin: 0 0 25px;
        padding: 0;
    }

    .alert-block {
        padding: 25px;
        background: url("assets/icons/book.png") right 25px top 5px no-repeat;
        background-size: 45px;
    }

    .worker-man-background {
        background: url("assets/backgrounds/man-768.png") right top no-repeat;
        background-position: right 0;
    }

    .header-slogan-image {
        width: 100%;
        margin-bottom: 25px;
    }

}

@media screen and (max-width: 767px) {
    .actions-clouds-steps {
        display: block;
    }

    .actions-clouds-cloud-wrap {
        width: auto;
        display: block;
    }

    .help-content-container {
        padding: 0;
    }

    .actions-clouds-cloud-wrap {
        display: block;
    }

    .help-content-container .column-block {
        width: auto;
        padding: 0 16px;
    }

    .header-slogan {
        padding: 25px 16px 0;
        margin: 0;
        width: auto;
    }

    .worker-man-background .full-width-wrapper {
        min-height: 0;
    }

    .worker-man-background {
        background: #fff;
    }

    .bot-info {
        display: block;
    }

    .bot-info-counter {
        margin-bottom: 32px;
    }

    .map-banner-title {
        font-size: 20px;
    }

    .map-container {
        left: 16px;
        right: 16px;
    }

    .map-banner {
        padding: 16px;
        background-size: 36px;
        background-position: top 9px right 16px;
    }

    .triangle-background {
        display: none;
    }

    .header-container {
        display: block;
        min-height: 0;
    }

    .map-wrapper {
        height: 534px;
    }

    .row-block {
        padding: 0;
        margin: 0;
    }

    .worker-women-background {
        padding: 0 16px;
        background-position: top 300px right;
        background-size: 300px;
        margin-bottom: 25px;
    }

    .button-container.mobile-flex-space-between {
        justify-content: space-between;
    }

    .footer-logo-container .footer-logo {
        width: 36px;
    }

    .footer-logo-container h2 {
        font-size: 14px;
    }
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.loader {
    margin: 1rem auto;
    font-size: 10px;
    border-top: 1.1em solid transparent;
    border-right: 1.1em solid transparent;
    border-bottom: 1.1em solid transparent;
    border-left: 1.1em solid #BB5B3F;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load 1.3s infinite linear;
    animation: load 1.3s infinite linear;
}

@-webkit-keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

